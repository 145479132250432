import { Component, OnDestroy, OnInit } from '@angular/core';
import packageJson from '../../package.json';
import { WindowService } from './commons/services/window.service';
import { MatDialog } from '@angular/material/dialog';
import { CookiesService } from './commons/services/cookies-service.service';
import { NcConfigComponent } from './commons/components/nc-config/nc-config.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

/**
 * Componente app.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
  /**
   * Versión actual de la app.
   */
  public version: string = packageJson.version;

  /**
   * Constructor.
   * @param windowService
   * @param cookiesService
   * @param dialog
   */
  constructor(
    private windowService: WindowService,
    private cookiesService: CookiesService,
    private dialog: MatDialog,
  ) { }

  /**
   * @ignore
   */
  ngOnInit(): void {
    if (this.windowService.isBrowser) {
      console.log('(Smart)Makings v.' + this.version);
      const selectedCookies = localStorage.getItem('selectedCookiesSM');
      if (!selectedCookies) {
        this.openCookiesConfigDialog();
      } else {
        this.loadScripts();
      }
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
  }

  /**
   * Abre el selector de cookies.
   */
  openCookiesConfigDialog() {
    this.dialog.open(NcConfigComponent, {
      disableClose: true,
      hasBackdrop: true,
      maxWidth: '100%',
      scrollStrategy: new NoopScrollStrategy(),
      panelClass: 'cookiesConfigDialog',
    }).afterClosed().subscribe((response) => {
      this.cookiesService.retrieveSelectedCookies();
      this.loadScripts();
    });
  }

  /**
   * Carga los scripts en función de las cookies aceptadas.
   */
  private loadScripts() {
    if (this.cookiesService.hasAnalysisCookies()) {
      // Crea y añade los elementos script al DOM
      const scriptElement = document.createElement('script');
      scriptElement.async = true;
      scriptElement.src =
        'https://www.googletagmanager.com/gtag/js?id=G-DBTB6ER75S';
      document.head.appendChild(scriptElement);

      // Crea y añade el script de configuración al DOM
      const configScript = document.createElement('script');
      configScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-DBTB6ER75S');
      `;
      document.head.appendChild(configScript);
    }

    if (this.cookiesService.hasFunctionalityCookies()) {
    }
  }
}

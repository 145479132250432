import { Injectable } from '@angular/core';
import { WindowService } from './window.service';

/**
 * Servicio para la gestión de las cookies.
 */
@Injectable({ providedIn: 'root' })
export class CookiesService {

  /**
   * Almacena las cookies seleccionadas por el usuario.
   */
  private selectedCookies: { [key: string]: boolean } = {};

  /**
   * Constructor.
   * @param windowService
   */
  constructor(
    private windowService: WindowService
  ) {
    this.retrieveSelectedCookies();
  }

  /**
   * Obtiene el valor de uno de los tipos de cookies.
   * @param cookieKey
   * @returns
   */
  getSelectedCookieValue(cookieKey: string): boolean {
    return this.selectedCookies[cookieKey] === true;
  }

  /**
   * Revisa si tiene aceptadas las cookies de análisis.
   * @returns
   */
  hasAnalysisCookies(): boolean {
    return this.getSelectedCookieValue('ANALYSIS');
  }

  /**
   * Revisa si tiene aceptadas las cookies de funcionalidad.
   * @returns
   */
  hasFunctionalityCookies(): boolean {
    return this.getSelectedCookieValue('FUNCTIONALITY');
  }

  /**
   * Obtiene las cookies seleccionadas por el usuario en localstorage.
   * @returns
   */
  retrieveSelectedCookies(): void {
    if (this.windowService.isBrowser) {
      const storedToggles = localStorage.getItem('selectedCookiesSM');
      this.selectedCookies = storedToggles ? JSON.parse(storedToggles) : {};
    }
  }
}

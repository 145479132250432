{
  "name": "app",
  "version": "0.0.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build && npm run sentry:sourcemaps",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:app": "node dist/app/server/server.mjs",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org ecapturedtech --project javascript-angular dist/app/browser && sentry-cli sourcemaps upload --org ecapturedtech --project javascript-angular dist/app/browser",
    "compodoc": "npx compodoc -p tsconfig.doc.json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.10",
    "@angular/cdk": "^18.2.11",
    "@angular/common": "^18.2.10",
    "@angular/compiler": "^18.2.10",
    "@angular/core": "^18.2.10",
    "@angular/forms": "^18.2.10",
    "@angular/material": "^18.2.11",
    "@angular/platform-browser": "^18.2.10",
    "@angular/platform-browser-dynamic": "^18.2.10",
    "@angular/platform-server": "^18.2.10",
    "@angular/router": "^18.2.10",
    "@angular/ssr": "^18.2.11",
    "@sentry/angular": "^8.39.0",
    "@sentry/cli": "^2.38.2",
    "@sentry/tracing": "^7.114.0",
    "@types/jspdf": "^2.0.0",
    "@types/lodash": "^4.17.7",
    "@types/moment": "^2.13.0",
    "express": "^4.18.2",
    "http-proxy-middleware": "^3.0.3",
    "jspdf": "^2.5.1",
    "ng-image-slider": "^10.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular/cli": "^18.2.11",
    "@angular/compiler-cli": "^18.2.10",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "jasmine-core": "~5.2.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.5.2"
  }
}

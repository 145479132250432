import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/components/home/home.component';
import { FaqsComponent } from './commons/components/faqs/faqs.component';
import { LegalComponent } from './commons/components/legal/legal.component';
import { PrivacyComponent } from './commons/components/privacy/privacy.component';
import { TosCookiesComponent } from './commons/components/tos-cookies/tos-cookies.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
      },
    ],
  },
  {
    path: 'faqs',
    component: FaqsComponent,
  },
  {
    path: 'legal',
    component: LegalComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'cookies',
    component: TosCookiesComponent,
  },
  {
    path: '**',
    component: HomeComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

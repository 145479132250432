<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<div class="email">
  <a href="mailto:info@smartmakings.com">
    <img src="/assets/images/header/email.svg" alt="Email">
  </a>
</div>
<div class="calendar">
  <a href="https://outlook.office365.com/book/SmartMakings1@SmartMakings.onmicrosoft.com/" target="_blank">
    <img src="/assets/images/header/calendar.svg" alt="calendar">
  </a>
</div>
